import * as React from 'react';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import {
  Alert,
  Button,
  Nav,
  NavList,
  NavItem,
  NavExpandable,
  Page,
  PageSidebar,
  Masthead,
  MastheadMain,
  MastheadToggle,
  MastheadBrand,
  PageToggleButton,
  Brand,
  MastheadContent,
  Toolbar,
  ToolbarContent,
  ToolbarGroup,
  ToolbarItem,
  ToolbarGroupVariant,
  PageProps,
  PageSection,
  Bullseye,
  Split,
  SplitItem,
} from '@patternfly/react-core';
import { Dropdown, DropdownToggle, DropdownItem } from '@patternfly/react-core/deprecated';
import { routes, IAppRoute, IAppRouteGroup } from '@app/routes';
import logo from '@app/bgimages/launch-logo.png';
import { AccountMenu } from '@app/AccountMenu/AccountMenu';
import { PortalAlerts } from '@app/PortalAlerts/PortalAlerts';
import { BarsIcon, ChevronLeftIcon, ChevronRightIcon, GripVerticalIcon, TimesIcon } from '@patternfly/react-icons';

import BugIcon from '@patternfly/react-icons/dist/esm/icons/bug-icon';
import LaptopIcon from '@patternfly/react-icons/dist/esm/icons/laptop-code-icon';
import EnvelopeIcon from '@patternfly/react-icons/dist/esm/icons/envelope-icon';
import GitlabIcon from '@patternfly/react-icons/dist/esm/icons/gitlab-icon';
import ChatIcon from '@patternfly/react-icons/dist/esm/icons/chat-icon';
import CatalogIcon from '@patternfly/react-icons/dist/esm/icons/catalog-icon';

import { About } from '@app/About/About';
import { useTranslation } from 'react-i18next';

import DashboardIcon from '@mui/icons-material/Dashboard';
import UsersIcon from '@patternfly/react-icons/dist/esm/icons/users-icon';
import OrganizationIcon from '@patternfly/react-icons/dist/esm/icons/building-icon';
import ProjectIcon from '@patternfly/react-icons/dist/esm/icons/project-diagram-icon';
import ExperimentIcon from '@patternfly/react-icons/dist/esm/icons/flask-icon';
import TutorialIcon from '@patternfly/react-icons/dist/esm/icons/book-open-icon';
import XDCsIcon from '@patternfly/react-icons/dist/esm/icons/code-branch-icon';
import ModelEditorIcon from '@patternfly/react-icons/dist/esm/icons/edit-icon';
import ResourcesIcon from '@patternfly/react-icons/dist/esm/icons/cubes-icon';
import './AppLayout.css';
import { SidebarContext, SidebarProvider, useSidebar } from '@app/Tutorials/SidebarContext';
import AdjustableSidebar from '@app/Tutorials/AdjustableSidebar';
import '../Tutorials/Sidebar.css';
import '../Tutorials/EventEmitter';

interface IAppLayout {
  children: React.ReactNode;
}

const AppLayout: React.FunctionComponent<IAppLayout> = ({ children }) => {
  const { isSidebarOpen, sidebarWidth } = useSidebar();
  const [isNavOpen, setIsNavOpen] = React.useState(true);
  const [isMobileView, setIsMobileView] = React.useState(false);
  const [helpDropdownOpen, setHelpDropdownOpen] = React.useState(false);
  const [isAboutOpen, setIsAboutOpen] = React.useState(false);

  const { t } = useTranslation();

  const toggleAbout = () => {
    setIsAboutOpen(!isAboutOpen);
  };

  const onNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const onPageResize: PageProps['onPageResize'] = React.useCallback((_event, { mobileView }) => {
    setIsMobileView(mobileView);
    if (mobileView) {
      setIsNavOpen(false);
    }
  }, []);

  const getRouteIcon = (label: string) => {
    switch (label.toLowerCase()) {
      case 'dashboard':
        return <DashboardIcon className="mui-icon" />;
      case 'users':
        return <UsersIcon />;
      case 'organizations':
        return <OrganizationIcon />;
      case 'projects':
        return <ProjectIcon />;
      case 'experiments':
        return <ExperimentIcon />;
      case 'tutorials':
        return <TutorialIcon />;
      case 'xdcs':
        return <XDCsIcon />;
      case 'model editor':
        return <ModelEditorIcon />;
      case 'resources':
        return <ResourcesIcon />;
      default:
        return null;
    }
  };

  function LogoImg() {
    const history = useHistory();
    function handleClick() {
      history.push('/');
    }
    return <img src={logo} onClick={handleClick} alt="SPHERE Logo" />;
  }

  const helpDropdownToggle = (isOpen: boolean) => {
    setHelpDropdownOpen(isOpen);
  };

  const helpDropdownSelect = () => {
    setHelpDropdownOpen(!helpDropdownOpen);
  };

  const helpDropdownItems = [
    <DropdownItem
      component="a"
      key="documentation"
      href="https://mergetb.gitlab.io/testbeds/sphere/sphere-docs/docs/experimentation"
      icon={<CatalogIcon />}
      target="blank"
    >
      Experiment Documentation
    </DropdownItem>,
    <DropdownItem key="chat" icon={<ChatIcon />} component="a" href="https://chat.mergetb.net/mergetb" target="blank">
      Support Chat
    </DropdownItem>,
    <DropdownItem
      key="source-code"
      icon={<GitlabIcon />}
      component="a"
      href="https://gitlab.com/mergetb"
      target="blank"
    >
      Source Code
    </DropdownItem>,
    <DropdownItem
      key="email-support"
      icon={<EnvelopeIcon />}
      component="a"
      href="mailto:contact-project+mergetb-support-email@incoming.gitlab.com"
      target="blank"
    >
      Email Support
    </DropdownItem>,
    <DropdownItem
      key="mergetb-issues"
      icon={<BugIcon />}
      component="a"
      description="Requires Gitlab account"
      href="https://gitlab.com/mergetb/support/-/issues"
      target="blank"
    >
      MergeTB Issues
    </DropdownItem>,
    <DropdownItem
      key="quickstart-tutorials"
      icon={<LaptopIcon />}
      component="a"
      description="Learn how to build your first project"
      href="/tutorials"
      target="blank"
    >
      Quickstart Tutorials
    </DropdownItem>,
  ];

  const headerToolbar = (
    <Toolbar id="toolbar" isFullHeight isStatic>
      <ToolbarContent>
        <ToolbarGroup
          variant={ToolbarGroupVariant['icon-button-group']}
          align={{ default: 'alignRight' }}
          spacer={{ default: 'spacerNone', md: 'spacerMd' }}
        >
          <ToolbarItem>
            <Button component="a" href="https://edu.sphere-testbed.net" target="blank" variant="plain">
              Class UI
            </Button>
          </ToolbarItem>
          <ToolbarItem>
            <Dropdown
              isPlain
              isFullHeight
              onSelect={helpDropdownSelect}
              toggle={
                <DropdownToggle onToggle={(_event, isOpen: boolean) => helpDropdownToggle(isOpen)} id="toggle-help">
                  Support
                </DropdownToggle>
              }
              isOpen={helpDropdownOpen}
              dropdownItems={helpDropdownItems}
            />
          </ToolbarItem>
          <ToolbarItem>
            <AccountMenu />
          </ToolbarItem>
          <ToolbarItem>
            <Button variant="plain" onClick={toggleAbout}>
              About
            </Button>
          </ToolbarItem>
        </ToolbarGroup>
      </ToolbarContent>
    </Toolbar>
  );

  const Header = (
    <Masthead>
      <MastheadToggle>
        <PageToggleButton
          variant="plain"
          aria-label="Global navigation"
          onClick={onNavToggle}
          isSidebarOpen={isNavOpen}
        >
          <BarsIcon
            style={{
              transform: isNavOpen ? 'scale(1.2)' : 'scale(1)',
              transition: 'transform 0.2s',
            }}
          />
        </PageToggleButton>
      </MastheadToggle>
      <MastheadMain>
        <Split hasGutter>
          <SplitItem>
            <MastheadBrand>
              <Brand alt={''}>{LogoImg()}</Brand>
            </MastheadBrand>
          </SplitItem>
          <SplitItem>
            <Bullseye>
              <h1>
                <div className="beta-text">BETA</div>
              </h1>
            </Bullseye>
          </SplitItem>
        </Split>
      </MastheadMain>
      <MastheadContent>{headerToolbar}</MastheadContent>
    </Masthead>
  );

  const location = useLocation();

  const renderNavItem = (route: IAppRoute, index: number) => (
    <NavItem key={`${route.label}-${index}`} id={`${route.label}-${index}`}>
      <NavLink exact to={route.path} activeClassName="pf-m-current">
        <span className="nav-icon">{getRouteIcon(route.label)}</span>
        {route.label}
      </NavLink>
    </NavItem>
  );

  const renderNavGroup = (group: IAppRouteGroup, groupIndex: number) => (
    <NavExpandable
      isExpanded={group?.isExapanded === true ? true : false}
      key={`${group.label}-${groupIndex}`}
      id={`${group.label}-${groupIndex}`}
      title={
        <>
          <span className="nav-icon">{getRouteIcon(group.label)}</span>
          {group.label}
        </>
      }
      isActive={group.routes.some((route) => route.path === location.pathname)}
    >
      {group.routes.map((route, idx) => route.label && renderNavItem(route, idx))}
    </NavExpandable>
  );

  const Navigation = (
    <Nav id="nav-primary-simple" theme="dark">
      <NavList id="nav-list-simple">
        {routes(t).map(
          (route, idx) => route.label && (!route.routes ? renderNavItem(route, idx) : renderNavGroup(route, idx))
        )}
      </NavList>
    </Nav>
  );

  const Sidebar = isNavOpen ? <PageSidebar theme="dark">{Navigation}</PageSidebar> : null;

  return (
    <Page
      header={Header}
      sidebar={Sidebar}
      onPageResize={onPageResize}
      additionalGroupedContent={isSidebarOpen ? <AdjustableSidebar /> : null}
      className={`merge-styles ${isNavOpen ? '' : 'nav-closed'} ${isSidebarOpen ? 'sidebar-open' : ''}`}
    >
      {isAboutOpen && <About toggle={toggleAbout} isOpen={isAboutOpen} />}
      <PortalAlerts />
      <PageSection
        padding={{ default: 'noPadding' }}
        style={{
          marginRight: isSidebarOpen ? `${sidebarWidth}px` : '0',
          transition: 'margin-right 0.1s ease-in-out',
        }}
      >
        {children}
      </PageSection>
    </Page>
  );
};

const AppLayoutWrapper: React.FunctionComponent<IAppLayout> = ({ children }) => {
  return (
    <SidebarProvider>
      <AppLayout>{children}</AppLayout>
    </SidebarProvider>
  );
};

export { AppLayoutWrapper as AppLayout };
