import React from 'react';
import {
  NotificationDrawer,
  NotificationDrawerBody,
  NotificationDrawerHeader,
  NotificationDrawerList,
  NotificationDrawerListItem,
  NotificationDrawerListItemBody,
  NotificationDrawerListItemHeader,
} from '@patternfly/react-core';
import { Dropdown, DropdownItem, DropdownPosition, KebabToggle } from '@patternfly/react-core/deprecated';
import { LocalizeDate } from '@app/lib/TaskStatusUtils';
import { number } from 'prop-types';

type NewsType = {
  id: string;
  title: string;
  desc: string;
  timestamp: string;
  level: any;
};

interface NewsProps {
  url?: string;
  data?: any;
}

export const NewsList: React.FunctionComponent<NewsProps> = (props) => {
  // grab data
  var title = props.data.title;
  var data = props.data.news;
  var len = data.length;

  // check local storage
  var prevreadstr = localStorage.getItem('news_receipts');
  var receipts = {};
  if (prevreadstr != null) {
    receipts = JSON.parse(prevreadstr);
  }

  // load receipts into the initial array of news items that are read
  var init_read = new Array(len).fill(false);

  data.forEach((x: NewsType, i: number) => {
    if (receipts[x.id] == true) {
      init_read[i] = true;
    }
  });

  const calculateUnread = (reads: boolean[]) => {
    var unread = 0;
    reads.forEach((x) => {
      if (!x) {
        unread++;
      }
    });

    return unread;
  };

  const [isKebabOpenToggle, setIsKebabOpenToggle] = React.useState(false);
  const [isReadMap, setIsReadMap] = React.useState(init_read);
  const [numUnread, setNumUnread] = React.useState(calculateUnread(init_read));

  // note that saveReceipts only stores receipts for news items that currently exist,
  // so if an ID is deleted from the news data, its read status will be reset (to unread)
  const saveReceipts = (reads: boolean[]) => {
    // if the data is empty somehow,
    // don't do anything (to avoid deleting the news receipts)
    if (data.length == 0) {
      return;
    }

    var receipts = {};

    data.forEach((x: NewsType, i: number) => {
      if (reads[i] == true) {
        receipts[x.id] = true;
      }
    });

    localStorage.setItem('news_receipts', JSON.stringify(receipts));
  };

  const onKebabToggle = (isOpen: boolean) => {
    setIsKebabOpenToggle(isOpen);
  };

  const markAllRead = (isRead: boolean) => () => {
    const newState = new Array(len).fill(isRead);
    setIsReadMap(newState);
    setIsKebabOpenToggle(false);
    setNumUnread(calculateUnread(newState));
    saveReceipts(newState);
  };

  const toggleRead = (index: number) => () => {
    const newState = [...isReadMap.slice(0, index), !isReadMap[index], ...isReadMap.slice(index + 1)];
    setIsReadMap(newState);
    setNumUnread(calculateUnread(newState));
    saveReceipts(newState);
  };

  const dropdownItems = [
    <DropdownItem key="action" component="button" onClick={markAllRead(!(calculateUnread(isReadMap) == 0))}>
      Mark All {calculateUnread(isReadMap) == 0 ? 'Unread' : 'Read'}
    </DropdownItem>,
  ];

  const newsMap = (newsItem: NewsType, i: number) => {
    // note that we interpret the description as html,
    // mostly so can embed links into the news items

    return (
      <NotificationDrawerListItem
        variant={newsItem.level}
        isRead={isReadMap[i]}
        onClick={toggleRead(i)}
        key={newsItem.id + '_item'}
      >
        <NotificationDrawerListItemHeader
          variant={newsItem.level}
          title={newsItem.title}
          srTitle={newsItem.title}
          key={newsItem.id + '_header'}
        />
        <NotificationDrawerListItemBody timestamp={LocalizeDate(newsItem.timestamp)} key={newsItem.id + '_body'}>
          <div
            dangerouslySetInnerHTML={{
              __html: newsItem.desc,
            }}
          />
        </NotificationDrawerListItemBody>
      </NotificationDrawerListItem>
    );
  };

  return (
    <NotificationDrawer>
      <NotificationDrawerHeader count={numUnread == 0 ? undefined : numUnread} title={title}>
        <Dropdown
          toggle={
            <KebabToggle onToggle={(_event, isOpen: boolean) => onKebabToggle(isOpen)} key="basic-kebab-toggle" />
          }
          isOpen={isKebabOpenToggle}
          isPlain
          dropdownItems={dropdownItems}
          key="news-notifications"
          position={DropdownPosition.right}
        />
      </NotificationDrawerHeader>
      <NotificationDrawerBody>
        <NotificationDrawerList>{data.map(newsMap)}</NotificationDrawerList>
      </NotificationDrawerBody>
    </NotificationDrawer>
  );
};
