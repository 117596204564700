import * as React from 'react';

import '@patternfly/react-core/dist/styles/base.css';
import '@app/app.css';
import '@app/sphere.css';

import { BrowserRouter as Router } from 'react-router-dom';
import { AppRoutes } from '@app/routes';
import AuthProvider from '@app/lib/AuthProvider';
import ErrorBoundary from '@app/lib/ErrorBoundary';
import { CustomTranslations, CustomLanguageFormats, IntlProvider, /* ThemeProvider, */ locales } from '@ory/elements';

// TODO move to usiung this everywhere.
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
const queryClient = new QueryClient();

// required styles for Ory Elements
import '@ory/elements/assets/normalize.css';
import '@ory/elements/style.css';

import '@app/lib/i18n';

const App: React.FunctionComponent = ({ callback }) => {
  return (
    <div ref={callback}>
      <Main />
    </div>
  );
};

const customTranslations: CustomLanguageFormats = {
  en: {
    ...locales.en,
    'login.title': 'Login',
    'identities.messages.1070004': 'Username',
  },
  nl: {
    ...locales.nl,
    'login.title': 'Inloggen',
    'identities.messages.1070004': 'Username',
  },
  af: {
    // merging English since no default Afrikaans translations are available
    ...locales.en,
    'login.title': 'Meld aan',
    'identities.messages.1070004': 'E-posadres',
  },
};

// left as an example. We use css to set fonts for ory elements
// const themeOverrides = {
//   fontFamily: 'Verdana',
//   fontFamilyMono: 'Courier New',
//   fontStyle: 'normal',
// };

const Main: React.FunctionComponent = () => (
  <React.StrictMode>
    <IntlProvider<CustomTranslations> locale="en" defaultLocale="en" customTranslations={customTranslations}>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Router>
            <ErrorBoundary>
              <AppRoutes />
            </ErrorBoundary>
          </Router>
        </QueryClientProvider>
      </AuthProvider>
    </IntlProvider>
  </React.StrictMode>
);

export default App;
