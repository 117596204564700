import * as React from 'react';

import { Alert, AlertGroup, AlertVariant, Timestamp } from '@patternfly/react-core';
import { env } from '@app/env.js';
import { useQuery } from '@tanstack/react-query';

type PortalAlert = {
  id: string;
  title: string;
  desc: string;
  timestamp: string;
  expiration: string;
  level: AlertVariant;
};

interface PortalAlertListProps {
  alerts: Array<PortalAlert>;
}

const PortalAlertList: React.FC<PortalAlertListProps> = ({ alerts }) => {
  return (
    <AlertGroup>
      {alerts.map((a, i) => (
        <Alert key={i} title={a.title} variant={a.level} isInline>
          <div dangerouslySetInnerHTML={{ __html: a.desc }} />
          <br />
          <Timestamp date={new Date(a.timestamp)} />
        </Alert>
      ))}
    </AlertGroup>
  );
};

const PortalAlerts: React.FC = () => {
  const url = env.REACT_APP_MERGE_ALERTS_URI;

  // fetch updated periodically
  const { data } = useQuery({
    queryKey: ['portalalerts'],
    queryFn: async () => {
      const resp = await fetch(url);
      if (!resp.ok) {
        throw new Error('Network error fetching portal alerts');
      }
      return resp.json();
    },
    refetchInterval: 60000, // 1 minute
    staleTime: 180000, // 3 minutes.
  });

  function isAlerts(o: any): o is PortalAlertListProps {
    return 'alerts' in o;
  }

  const alerts = React.useMemo(() => {
    if (data) {
      if (isAlerts(data)) {
        return data.alerts;
      }
    }
    return undefined;
  }, [data]);

  return <>{alerts && <PortalAlertList alerts={alerts} />}</>;
};

export { PortalAlerts };
